module.exports = {
  sanity: {
    projectId: process.env.SANITY_PROJECT_ID,
    dataset: process.env.SANITY_DATASET,
    token: process.env.SANITY_TOKEN,
  },
  eventbrite: {
    organizationId: process.env.EVENTBRITE_ORGANIZATION_ID,
    accessToken: process.env.EVENTBRITE_ACCESS_TOKEN,
  },
};
