import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

// cfr: https://github.com/sanity-io/gatsby-source-sanity#using-images-outside-of-graphql

export const Figure = ({ value }) => {
  // console.log(value);
  if (!value || !value.asset || !value.asset._ref) {
    console.log("==> Error: getGatsbyImageData api changed");
    return null;
  }
  const imageAssetId = value.asset._ref;
  // console.log('sanity', clientConfig.sanity);

  const gatsbyImageData = getGatsbyImageData(
    imageAssetId,
    {
      layout: "constrained",
    },
    clientConfig.sanity
  );

  return (
    <GatsbyImage className="block" image={gatsbyImageData} alt="post image" />
  );
};
