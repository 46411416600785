import React from "react";
import { graphql } from "gatsby";

import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../components/layout";
import Post from "../components/post/post.component";
import Seo from "../components/seo";

// we get id from context in gatsby-node
export const query = graphql`
  query PostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      publishedAt
      categories {
        title
      }
      title
      _rawBody
      mainImage {
        asset {
          url
        }
      }
      author {
        name
        image {
          asset {
            gatsbyImageData(width: 48, aspectRatio: 1, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

const PostTemplate = ({ data, errors }) => {
  const post = data && data.post;
  // console.log(post);

  return (
    <Layout pageClass="posts">
      <Seo title={post.title || "News"} />
      {errors && (
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-three-quarters">
              <GraphQLErrorList errors={errors} />
            </div>
          </div>
        </div>
      )}
      {post && <Post {...post} />}
    </Layout>
  );
};

export default PostTemplate;
