import React from "react";
import { PortableText } from "@portabletext/react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { respH } from "../../lib/helpers";

import { Figure } from "../../components/Figure";

import "./post.styles.scss";

const components = {
  types: {
    image: Figure,
  },
};

// We receive props da postPageTemplate
const Post = ({
  title,
  publishedAt,
  mainImage,
  categories,
  _rawBody: body,
  author,
}) => {
  const heroStyle = {
    height: "100%",
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundImage: `linear-gradient( 109.6deg, rgba(156,252,248,1), transparent, rgba(110,123,251,1)), url(${mainImage.asset.url})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  };

  return (
    <>
      <section className="hero is-large" style={heroStyle}>
        <div className="hero-body">
          <div className="container is-max-desktop">
            <h1
              className={`title ${respH(
                1
              )} has-text-white has-text-centered mb-6 is-capitalized`}
            >
              {title}
            </h1>
          </div>
        </div>
      </section>

      <section className="section is-medium">
        <div className="columns mb-5">
          <div className="column has-max-ch mx-auto">
            <div className="media">
              <div className="media-left">
                <GatsbyImage
                  imgClassName="has-rounded-corners"
                  image={author.image.asset.gatsbyImageData}
                  alt="image autore"
                />
              </div>
              <div className="media-content">
                <p className="subtitle is-6 is-capitalized">
                  {categories &&
                    categories.map((cat, i) => (
                      <span key={i} className="is-size-6">
                        #{cat.title}{" "}
                      </span>
                    ))}{" "}
                  |{" "}
                  {format(new Date(publishedAt), "d, MMMM, yyyy", {
                    locale: it,
                  })}
                </p>
                <p className="title is-4 is-capitalized">{title}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column has-max-ch mx-auto">
            <div className="content">
              <PortableText value={body} components={components} />
              {/* <pre>{JSON.stringify(body, null, 2)}</pre> */}
            </div>
            <Link
              className="button is-link is-light is-fullwidth mt-6"
              to="/news"
            >
              Back
            </Link>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column"></div>
        </div>
      </section>
    </>
  );
};

export default Post;
